import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import Tag from 'antd/lib/tag';

import './NavItem.scss';
import { IconCustom, NewTag } from 'components';
import { ROUTES } from 'other';

/**/
type Props = {
  extra: ReactElement;
  icon: string;
  isCollapsed: boolean;
  isLoggedIn: boolean;
  path: string;
  title: string;
};

/**/
function isActive(title) {
  return (match, location) => {
    if (!match) return false;
    return location.state?.id === title;
  };
}

/**
 *
 */
function NavItem(props: Props) {
  const { extra, icon, isCollapsed, isLoggedIn, path, title } = props;
  const cls = classnames('NavItem', {
    'NavItem--collapsed': isCollapsed
  });

  const _props = isLoggedIn
    ? {
        exact: path === ROUTES.HOME,
        to: path
      }
    : {
        isActive: isActive(title),
        to: {
          pathname: path,
          state: { id: title }
        }
      };

  return (
    <NavLink activeClassName="NavItem--active" className={cls} {..._props}>
      <div className="NavItem__box">
        <IconCustom className="NavItem__icon" type={icon} />
      </div>

      {isCollapsed || title}
      {isCollapsed || (extra && <NewTag />)}
    </NavLink>
  );
}

/**
 *
 */
export default NavItem;
