import ReactGA from 'react-ga4';
import ReactLinkedInTag from 'react-linkedin-insight';
import ReactPixel from 'react-facebook-pixel';

import { ANALYTICS, ENV } from 'other';
import { history } from 'store';

// Initializes Facebook Pixel
export function initFBP(): void {
  ReactPixel.init(ANALYTICS.FACEBOOK_PIXEL, null, {
    autoConfig: true,
    debug: false
  });
  ReactPixel.pageView();
}

// Initializes Google Analytics
export function initGA(): void {
  ReactGA.initialize(ENV.GA_MEASUREMENT_ID);
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search
  });

  history.listen(({ pathname }) => {
    ReactGA.set({ pathname });
    ReactGA.send('pageview');
  });
}

// Initializes Linkedin Insight
export function initLIT(): void {
  // @ts-ignore
  ReactLinkedInTag.init(ANALYTICS.LINKEDIN_INSIGHT, 'dc');
}
