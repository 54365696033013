import React from 'react';
import classnames from 'classnames';

import './Aside.scss';
import './AsideContent.scss';
import { BannerCarousel, isIPad, Navigation, TrialLink } from 'components';
import ThemeToggle from './ThemeToggle/ThemeToggle';
import { EBannerType, TNavigationItem } from 'types';

/**/
type Props = {
  isLoggedIn: boolean;
  isOpen: boolean;
  items: TNavigationItem[];
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

/**
 *
 */
function AsideContent(props: Props) {
  const { isLoggedIn, isOpen, items, onMouseEnter, onMouseLeave } = props;

  const contentCls = classnames('AsideContent', {
    'AsideContent--open': isOpen
  });

  const handler = isIPad() ? onMouseLeave : (Function.prototype as any);

  return (
    <div
      className={contentCls}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="AsideContent__content">
        {isOpen && <TrialLink isPrimary={true} />}

        <Navigation
          closeAside={handler}
          isAsideOpen={isOpen}
          isLoggedIn={isLoggedIn}
          items={items}
        />
      </div>

      <div className="AsideContent__footer">
        {isOpen && isLoggedIn && (
          <div className="Aside__banner">
            <BannerCarousel type={EBannerType.MENU} />
          </div>
        )}

        <ThemeToggle />
      </div>
    </div>
  );
}

/**
 *
 */
export { AsideContent };
