import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import Button from 'antd/lib/button';
import classnames from 'classnames';
import isequal from 'lodash.isequal';
import Modal from 'antd/lib/modal';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';

import './LayerControl.scss';
import AreaListWidget from 'pages/Map/controls/AreaControl/AreaListWidget';
import { IconCustom } from 'components';
import {
  withModalState,
  TInjectedModalStateProps
} from 'components/withModalState';

import { EMapLayer } from 'types';
import { TMapOptionsState } from 'store';

export type LayerControlProps = TInjectedModalStateProps & {
  buttonCloneClassname?: string;
  children: ReactElement | ReactElement[];
  currentMapLayer: EMapLayer;
  isDefaultOpen: boolean;
  updateMapOptions: (options: Partial<TMapOptionsState>) => void;
};

const defaultModalProps = {
  className: 'LayerControl__container',
  closable: false,
  destroyOnClose: true,
  footer: null,
  icon: null,
  title: null
};

/**
 *
 */
class LayerControl extends React.Component<LayerControlProps> {
  private static readonly BTN_ID = 'layer_btn';

  /**/
  componentDidMount() {
    const { isDefaultOpen, onModalShow } = this.props;
    isDefaultOpen && onModalShow();
  }

  /**/
  shouldComponentUpdate(nextProps: Readonly<LayerControlProps>): boolean {
    return !isequal(this.props, nextProps);
  }

  /**/
  handleChange = (e: RadioChangeEvent) => {
    const { onModalHide, updateMapOptions } = this.props;
    updateMapOptions({ layer: e.target.value });
    onModalHide();
  };

  /**/
  renderBtnClone(): ReactElement {
    const { buttonCloneClassname, isModalVisible, onModalHide } = this.props;
    if (!isModalVisible) return null;

    const cls = classnames(
      'MapControlButton LayerControl__button LayerControl__button--atop',
      buttonCloneClassname
    );
    const layerBtn = document.getElementById(LayerControl.BTN_ID);
    const { y } = layerBtn.getBoundingClientRect();
    const style = { top: y };

    const btn = (
      <Button className={cls} onClick={onModalHide} shape="round" style={style}>
        <IconCustom title="Show layers" type="layers" />
      </Button>
    );
    return ReactDOM.createPortal(btn, document.querySelector('.Main'));
  }

  render() {
    const {
      children,
      currentMapLayer,
      isModalVisible,
      onModalHide,
      onModalShow
    } = this.props;

    const cls = classnames('MapControlButton', 'LayerControl__button', {
      'LayerControl__button--active': isModalVisible
    });

    const buttonProps = {
      children: <IconCustom title="Show layers" type="layers" />,
      className: cls,
      id: LayerControl.BTN_ID,
      onClick: onModalShow,
      shape: 'round'
    } as any;

    const modalProps = {
      ...defaultModalProps,
      getContainer: false as any,
      onCancel: onModalHide,
      open: isModalVisible
    };

    return (
      <section className="LayerControl">
        <Button {...buttonProps} />

        <Modal {...modalProps}>
          {this.renderBtnClone()}

          <section className="LayerControl__section">
            <header className="LayerControl__header">
              <h3 className="LayerControl__title app-h6">Map Layers</h3>
            </header>

            <Radio.Group
              className="LayerControl__layers"
              onChange={this.handleChange}
              value={currentMapLayer}
            >
              {children}
            </Radio.Group>
          </section>

          <AreaListWidget />
        </Modal>
      </section>
    );
  }
}

/**/
const Component = withModalState(LayerControl);
export { Component as LayerControl };
