import {
  TVesselLocation,
  TVesselTrack,
  TVesselTrackPoint,
  TVesselTrackVector
} from 'types';

/**/
export function isPointNewer(
  track: TVesselTrack,
  location: TVesselLocation
): boolean {
  if (!location) return false;

  const latest = track.locations[track.locations.length - 1];
  if (!latest) return false;

  return (
    new Date(location.lastUpdate).getTime() >
    new Date(latest.lastUpdate).getTime()
  );
}

/**/
export function appendTrackPoint(
  track: TVesselTrack,
  location: TVesselLocation
): TVesselTrack {
  const point = {
    heading: location.heading,
    id: Date.now(),
    lastUpdate: location.lastUpdate,
    latitude: location.latitude,
    longitude: location.longitude,
    speed: location.speed,
    status: location.status
  };

  return {
    ...track,
    locations: [...track.locations, point]
  };
}

/**/
export const VECTOR_VALIDITY_MAX_INTERVAL = 30 * 60 * 1000; // 30min
const MINUTES_15_AS_HOUR = 0.25;
const METERS_IN_KNOT = 1852;
const METERS_IN_1_DEGREE = 111111;

/**/
function deg2rad(deg: number): number {
  return (deg * Math.PI) / 180;
}

/**/
function getVectorEndPoint(point: TVesselTrackPoint): {
  latitude: number;
  longitude: number;
} {
  const { heading, latitude, longitude, speed } = point;
  const distancePrediction = speed * MINUTES_15_AS_HOUR * METERS_IN_KNOT;
  const k = distancePrediction / METERS_IN_1_DEGREE;

  const northwardsDistance = k * Math.cos(deg2rad(heading));
  const eastwardsDistance =
    (k * Math.sin(deg2rad(heading))) / Math.cos(deg2rad(latitude));

  return {
    latitude: latitude + northwardsDistance,
    longitude: longitude + eastwardsDistance
  };
}

/**/
export function isPointValid(lastUpdate: string, heading: number): boolean {
  const timeDiff = Date.now() - new Date(lastUpdate).getTime();
  return timeDiff <= VECTOR_VALIDITY_MAX_INTERVAL && heading <= 360;
}

/**/
export function createVector(
  vesselId: number,
  point: TVesselTrackPoint,
  color: string = 'grey'
): TVesselTrackVector {
  const { heading, lastUpdate, latitude, longitude, speed } = point;
  return {
    end: getVectorEndPoint(point),
    start: {
      latitude: latitude,
      longitude: longitude
    },
    color: color,
    heading: heading,
    id: Math.random().toString(36).substring(2),
    lastUpdate: lastUpdate,
    speed: speed,
    vesselId: vesselId
  };
}
