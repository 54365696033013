import { ENDPOINTS, stringComparator } from 'other';
import { fetchSet } from './farmsConstants';

import { TFarm } from 'types';
import { http, THttpResponse } from 'services';
import { TState } from 'store';

const sortFarms = (a: TFarm, b: TFarm): number =>
  stringComparator(a.name, b.name);

export function fetchFarmsAction() {
  return (dispatch, getState) => {
    const { farms } = getState() as TState;
    if (farms.isPending) return;

    dispatch(fetchSet.request());

    http
      .send(ENDPOINTS.FARMS)
      .then(({ data }: THttpResponse<TFarm[]>) =>
        dispatch(
          fetchSet.success({
            farms: data.sort(sortFarms)
          })
        )
      )
      .catch((e) => dispatch(fetchSet.error(e)));
  };
}
