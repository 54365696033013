import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './NotificationListItem.scss';
import {
  getNotificationContent,
  getNotificationDate,
  getNotificationIcon,
  getNotificationPath
} from './helpers';
import { TNotification } from 'types';

/**/
type Props = {
  notification: TNotification;
  onItemClick: (id: number) => void;
};

const ntf = {
  date: '2024-05-22T14:53:22.969Z',
  id: 321,
  read: false,
  type: 'PROMOTION',
  body: JSON.stringify({
    articleId: 17211,
    articleName: 'Skal du fiske i saltvann eller ferskvann?',
    sourceId: 85,
    sourceName: 'FF QA Service Provider',
    sourceLogo: {
      path: '85/logo/ClintEastwood 3-92233abffd9d153c31e116bb7c663c5a.jpg'
    }
  })
};

/**
 *
 */
function NotificationListItem({ notification, onItemClick }: Props) {
  const { createdAt, id, read, body, type } = notification;
  const handleClick = () => onItemClick(id);

  const cls = classnames('NotificationListItem', {
    'NotificationListItem--read': read
  });

  return (
    <Link
      className={cls}
      onClick={handleClick}
      to={getNotificationPath(body, type)}
    >
      <div className="NotificationListItem__background" />

      <div className="NotificationListItem__content">
        <div className="NotificationListItem__prefix">
          {getNotificationIcon(body, type)}
        </div>

        <div>
          <div className="app-body-2 NotificationListItem__text">
            {getNotificationContent(body, type)}
          </div>

          <div className="app-caption NotificationListItem__date">
            {getNotificationDate(createdAt)}
          </div>
        </div>
      </div>
    </Link>
  );
}

/**/
export { NotificationListItem };
