export type TNotification = {
  body: string;
  createdAt: string;
  id: number;
  read: boolean;
  type: ENotification;
};

export type TNotificationRaw = {
  body: string;
  date: string;
  id: number;
  read: boolean;
  type: ENotification;
};

export enum ENotification {
  ADMIN = 'ADMIN',
  PROMOTION = 'PROMOTION'
}
