import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'antd/lib/modal';
import ReactGA from 'react-ga4';

import './TrialModal.scss';
import { ENDPOINTS } from 'other';
import {
  hideTrialModalAction,
  showTrialModalAction
} from 'store/trialModal/trialModalActions';
import { http } from 'services';
import { IconCustom } from 'components/common/IconCustom/IconCustom';
import {
  LoaderOverlay,
  MESSAGES,
  TrialForm,
  TrialInfo,
  TrialThankYou
} from 'components';

import { TState } from 'store';

const closeIcon = <IconCustom title="Close preview" type="close" />;

export type TTrialModal = {
  isModalVisible: boolean;
  showTrialModal: () => void;
  hideTrialModal: () => void;
};

type Props = TTrialModal & {
  isLoggedIn: boolean;
};

type State = {
  isLoading: boolean;
  isResolved: boolean;
  msgResponse: string;
};

class Component extends React.PureComponent<Props, State> {
  static propTypes;

  state: State = {
    isLoading: false,
    isResolved: false,
    msgResponse: null
  };

  handleSubmit = async ({ email }: any): Promise<any> => {
    this.setState({
      isLoading: true
    });

    try {
      await http.send({
        body: { email },
        method: 'POST',
        url: ENDPOINTS.TRIAL
      });

      this.setState({
        isLoading: false,
        isResolved: true
      });

      ReactGA.event({
        action: 'Trial "Get Started" button is clicked',
        category: 'Trial',
        label: JSON.stringify({ location: window.location.pathname }),
        transport: 'beacon'
      });
    } catch (e) {
      this.setState({
        isLoading: false,
        msgResponse: MESSAGES.RESPONSE_ERROR
      });
    }
  };

  render() {
    const { msgResponse, isLoading, isResolved } = this.state;
    const { hideTrialModal: handleHideTrialModal } = this.props;

    return (
      <Modal
        centered={true}
        className="TrialModal"
        closeIcon={closeIcon}
        destroyOnClose={true}
        footer={null}
        maskClosable={false}
        open={this.props.isModalVisible}
        width={800}
        onCancel={handleHideTrialModal}
      >
        <LoaderOverlay isLoading={isLoading} isTransparent={true}>
          {isResolved ? (
            <TrialThankYou />
          ) : (
            <>
              <TrialInfo />
              <TrialForm
                responseStatus={msgResponse}
                onSubmit={this.handleSubmit}
              />
            </>
          )}
        </LoaderOverlay>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  hideTrialModal: hideTrialModalAction,
  showTrialModal: showTrialModalAction
};

const mapStateToProps = ({ trialModal: { isModalVisible } }: TState) => ({
  isModalVisible
});

Component.propTypes = {
  hideTrialModal: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  showTrialModal: PropTypes.func.isRequired
};

export const TrialModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
