import React, { ReactElement } from 'react';
import dayjs from 'dayjs';

import { IconCustom } from '../../common/IconCustom/IconCustom';
import { PROVIDER_IMAGE_URL, ROUTES } from 'other';
import { ENotification } from 'types';

/**
 *
 */
export function getPromoIcon(body: string): ReactElement {
  const { sourceLogo } = JSON.parse(body);
  if (!sourceLogo) {
    return (
      <IconCustom
        className="NotificationListItem__icon"
        title="Open link"
        type="newspaper"
      />
    );
  }

  const src = PROVIDER_IMAGE_URL + '/' + sourceLogo.path;
  return <img src={src} alt="Logo" className="NotificationListItem__logo" />;
}

/**
 *
 */
export function getNotificationIcon(
  body: string,
  type: ENotification
): ReactElement {
  switch (type) {
    case ENotification.PROMOTION:
      return getPromoIcon(body);

    case ENotification.ADMIN:
    default:
      return (
        <IconCustom
          className="NotificationListItem__icon"
          title="Open link"
          type="speed"
        />
      );
  }
}

/**
 *
 * @param date
 */
export function getNotificationDate(date: string): string {
  if (!date || typeof date !== 'string') return '';

  const offset = new Date().getTimezoneOffset();
  const d = dayjs(date).add(-offset, 'minutes');
  const now = dayjs();

  if (d.isToday()) {
    const hours = now.diff(d, 'hours');
    if (hours >= 1) {
      return Math.floor(hours) + 'h ago';
    }

    const min = now.diff(d, 'minutes');
    return Math.floor(min) + 'min ago';
    /**/
  } else if (d.isYesterday()) {
    const time = d.format('HH:mm');
    return 'Yesterday, ' + time;
  }

  return `${d.format('D MMM YYYY')}, ${d.format('HH:mm')}`;
}

/**
 *
 */
export function getNotificationContent(
  body: string,
  type: ENotification
): ReactElement {
  switch (type) {
    case ENotification.PROMOTION:
      return getPromotedNotificationContent(body);

    case ENotification.ADMIN:
    default:
      return <span>{body}</span>;
  }
}

/**
 *
 */
export function getNotificationPath(body: string, type: ENotification): string {
  switch (type) {
    case ENotification.PROMOTION:
      return ROUTES.NEWS + '/' + JSON.parse(body).articleId;

    case ENotification.ADMIN:
    default:
      return ROUTES.NEWS;
  }
}

/**
 *
 */
function getPromotedNotificationContent(body: string): ReactElement {
  try {
    const { articleName, sourceName } = JSON.parse(body);

    return (
      <span>
        <span>
          <b>{sourceName}</b> published the article{' '}
        </span>
        <span className="NotificationListItem__link">{articleName}</span>
      </span>
    );
  } catch (e) {
    window.console.error(e);
    return <span>Something went wrong.</span>;
  }
}
