// @ts-nocheck
import React from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchNotificationsAction,
  markAsReadAction
} from 'store/notifications/notificationActions';
import NotificationMenu from './NotificationMenu';
import { ROUTES } from 'other';
import { TState } from 'store';

/**
 *
 */
function NotificationButton() {
  const dispatch = useDispatch();

  const { isLoading, isLogged, notifications, total } = useSelector(
    (state: TState) => ({
      isLoading: state.notifications.isPending,
      isLogged: !!state.session.user,
      notifications: state.notifications.notifications,
      total: state.notifications.pagination.total
    })
  );

  const props = {
    hasMore: notifications?.length < total,
    isLoading: isLoading,
    notifications,
    onClear: () => dispatch(markAsReadAction([])),
    onFetch: (fetchMore?: boolean) =>
      dispatch(fetchNotificationsAction(fetchMore)),
    onItemClick: (id: number) => {
      dispatch(push(id));
      dispatch(markAsReadAction([id]));
    }
  };

  return isLogged && <NotificationMenu {...props} />;
}

/**/
export default NotificationButton;
