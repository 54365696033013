import { isProdHost } from './helpers';
import packageJson from '../../package.json';

export const VERSION = packageJson.version;

export const API_URL = process.env.REACT_APP_API_URL;
export const MEDIA_BASE_URL = process.env.REACT_APP_MEDIA_URL;

export const IMAGE_THUMB_URL = `${MEDIA_BASE_URL}/vessels/thumbnails`;
export const ARTICLE_IMAGE_URL = `${MEDIA_BASE_URL}/articles`;
export const PROVIDER_IMAGE_URL = `${MEDIA_BASE_URL}/serviceProviders`;

/**/
const ENV_CONFIG = require(`./config.${process.env.REACT_APP_ABBR}`).CONFIG;
const GA_MEASUREMENT_ID = {
  PROD: 'G-GRG1MSRCXQ',
  TEST: 'G-LZKMZ8R548'
};

export const ENV = {
  ...ENV_CONFIG,
  GA_MEASUREMENT_ID: isProdHost()
    ? GA_MEASUREMENT_ID.PROD
    : GA_MEASUREMENT_ID.TEST
};

/**/
export const ENDPOINTS = {
  COMPANIES: '/companies',
  CRM_COMPANIES: '/crm/companies',
  CRM_CONTACTS: '/crm/contacts',
  CRM_NOTES: '/crm/notes',
  CRM_PROPOSALS: '/crm/proposals',
  CRM_VESSELS: '/crm/vessels',
  CUSTOM_AREA: '/area/custom',
  DICTIONARIES: '/dictionaries',
  EVENT: '/event',
  FARMS: '/fishfarms',
  FARMS_LOCATION: '/fishfarms/location',
  FARM_AREAS: '/fishfarms/areas',
  FAVORITE_VESSELS: '/fleet/',
  FLEET: '/fleet',
  LAYER_INFO: '/map/layerInfo/cns',
  LOGIN: '/login',
  LOGOUT: '/logout',
  NEWS: '/news',
  NOTIFICATIONS: '/notifications',
  NOTIFICATIONS_READ: '/notifications/markasread',
  PERSON: '/person',
  PERSONS: '/persons',
  POWER_BI_TOKEN: '/analytics/powerbi/workspace',
  PROMOTION: '/promotions',
  PROMOTION_COST: '/promotions/price',
  PUSH_NOTIFICATION_TOKEN: '/push-notification-token',
  SCHEDULE_ITEM: '/events/item',
  SEARCH: '/search',
  SERVICE: '/serviceProvider',
  SERVICE_BRANCHES: '/serviceProvider/branches',
  SERVICE_PROVIDER: '/serviceProvider',
  SERVICE_SUBSCRIPTIONS: '/serviceProvider/subscriptionTypes',
  SESSION: '/user/active',
  TABLEAU_TOKEN: '/tableau/token',
  TRIAL: '/user/register/trial',
  USER_ROLES: '/user/roles',
  USER_USERS: '/user/users',
  VESSEL: '/vessel',
  VESSELS: '/vessels',
  VESSELS_HISTORICAL_TRACK: '/vessels/historical-track',
  VESSELS_LOCATION: '/vessels/location',
  ZONES: '/area/production'
};

export const SUB_POINTS = {
  ALL: 'all',
  ANNUAL_REPORTS: '/annualreports',
  APPROVE_BOOKING: '/booking/accept',
  BANNER: '/banner',
  BOOK_MEETING: '/book',
  CANCEL_MEETING: '/booking/cancel',
  CATCHES: '/catches',
  FARMS: '/farms',
  LOCATION: '/location',
  PAYMENTS: '/payments',
  PHOTOS: 'photos',
  PRODUCT: '/product',
  PROVIDER: '/provider',
  REJECT_BOOKING: '/booking/reject',
  VESSELS: '/vessels'
};

export const LOCALE = 'en_GB';
export const FARMS_LOCATIONS_UPDATE_INTERVAL = 60 * 60 * 1000;
export const VESSELS_LOCATIONS_UPDATE_INTERVAL = 60 * 1000;
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_DMY = 'DD.MM.YYYY';
export const DATE_FORMAT_DMY_HM = 'DD-MM-YYYY hh:mm';

export const GOOGLE_MAP_KEY = {
  key: 'AIzaSyACaSoM_cjwvm8hWkaaB8T1aLRujRERXDU',
  language: 'en'
};

export const WYSIWYG_EMPTY_STRING = '<span />';

// export const CHUNK_LIMIT = 1048576; // 1MB // DO NOT REMOVE
export const CHUNK_LIMIT = 20971520; // 20MB
export const ERR_MESSAGE_DURATION = 5; // seconds

export const SUB_PROJECTS = {
  EXPO: 'https://expo.fishfacts.com',
  EXPO_FLOOR_PLAN: '/news/793',
  REGISTRATION: 'https://register.fishfacts.com/'
};

export const FF_CONSENT = 'FF_CONSENT';

export const ANALYTICS = {
  FACEBOOK_PIXEL: '244307253917177',
  LINKEDIN_INSIGHT: 3197193,

  // eslint-disable-next-line sort-keys
  LABELS: {
    EVENTS: 'EVENTS',
    NEWS: 'NEWS'
  }
};
