import dayjs from 'dayjs';
import { push } from 'connected-react-router';

import {
  createSet,
  EAreasActions,
  fetchSet,
  fetchZonesSet,
  removeSet,
  updateSet
} from './areasConstants';
import { deleteConfirm } from 'components';
import { ENDPOINTS, ENV, ROUTES, updateArrayItem } from 'other';
import { http, SettingsService, THttpResponse } from 'services';

import { TArea, TAreaPoint, TZone } from 'types';
import { TState } from 'store';

/**/
export function fetchAreasAction() {
  return (dispatch) => {
    dispatch(fetchCustomAreasAction());
    ENV.IS_AF && dispatch(fetchZonesAction());
  };
}

/**/
function filterStaleAreas(a: TArea): boolean {
  if (!a) return false;
  return (
    dayjs(a.end).isValid() && dayjs(a.end || null).unix() * 1000 > Date.now()
  );
}

/**/
export function fetchCustomAreasAction() {
  return (dispatch, getState) => {
    const { areas } = getState() as TState;
    const selectedAreas: number[] =
      SettingsService.readSettings()[SettingsService.SELECTED_AREAS];

    if (areas.areas) return;

    dispatch(fetchSet.request());

    http
      .send(ENDPOINTS.CUSTOM_AREA)
      .then(({ data }: THttpResponse<TArea[]>) => {
        const selected = (selectedAreas || [])
          .map((id: number) => data.find((a: TArea) => a.id === id))
          .filter(filterStaleAreas)
          .map((a: TArea) => a.id);

        dispatch(
          fetchSet.success({
            areas: data,
            selectedAreas: selected
          })
        );
      })
      .catch((e) => dispatch(fetchSet.error(e)));
  };
}

/**/
export function removeAreaAction(id: number) {
  return (dispatch) =>
    deleteConfirm('Remove area permanently?', () =>
      dispatch(deleteAreaAction(id))
    );
}

/**/
export function deleteAreaAction(id: number) {
  return (dispatch, getState) => {
    const { areas } = getState() as TState;
    dispatch(removeSet.request());

    http
      .send({
        method: 'DELETE',
        url: `${ENDPOINTS.CUSTOM_AREA}/${id}`
      })
      .then(() => {
        const selected = areas.selectedAreas.filter(
          (itemId: number) => itemId !== id
        );
        const update = areas.areas.filter((a: TArea) => a.id !== id);

        dispatch(
          removeSet.success({
            areas: update,
            selectedAreas: selected
          })
        );
      })
      .catch((e) => dispatch(removeSet.error(e)));
  };
}

/**/
export function selectAreaAction(id: number) {
  return (dispatch, getState) => {
    const {
      areas: { selectedAreas }
    } = getState() as TState;

    const isChecked = selectedAreas.includes(id);

    const update = isChecked
      ? selectedAreas.filter((_id: number) => _id !== id)
      : [...selectedAreas, id];

    SettingsService.writeSettings({ [SettingsService.SELECTED_AREAS]: update });

    dispatch({
      type: EAreasActions.SELECT_AREA,
      payload: { selectedAreas: update }
    });
  };
}

/**/
export function showAreaAction(area: TArea) {
  return (dispatch) => dispatch(push(getPath(area)));
}

/**/
export function getPath(area: TArea): string {
  const accum = { lat: 0, lng: 0 };
  const reducer = (sum, curr: TAreaPoint) => ({
    lat: sum.lat + curr.latitude,
    lng: sum.lng + curr.longitude
  });

  const length = area.area.points.length;
  const center = area.area.points.reduce(reducer, accum);
  return `${ROUTES.MAP}?zoom=9&lat=${center.lat / length}&lng=${
    center.lng / length
  }`;
}

/**/
export function saveAreaAction(area: Partial<TArea>) {
  return (dispatch) =>
    dispatch('id' in area ? updateAreaAction(area) : createAreaAction(area));
}

/**/
export function createAreaAction(area: Partial<TArea>) {
  return (dispatch, getState) => {
    const { areas } = getState() as TState;
    dispatch(createSet.request());

    http
      .send({
        body: area,
        method: 'POST',
        url: ENDPOINTS.CUSTOM_AREA
      })
      .then(({ data }: THttpResponse<TArea>) => {
        const payload =
          (areas.areas && {
            areas: [data, ...areas.areas],
            selectedAreas: [data.id, ...areas.selectedAreas]
          }) ||
          void 0;

        dispatch(createSet.success(payload));
        dispatch(showAreaAction(data));
      })
      .catch((e) => dispatch(createSet.error(e)));
  };
}

/**/
export function updateAreaAction(area: Partial<TArea>) {
  return (dispatch, getState) => {
    const { areas } = getState() as TState;
    dispatch(updateSet.request());

    http
      .send({
        body: area,
        method: 'PUT',
        url: `${ENDPOINTS.CUSTOM_AREA}/${area.id}`
      })
      .then(({ data }: THttpResponse<TArea>) => {
        const update = updateArrayItem(data, areas.areas);
        const payload = {
          ...(areas.areas && { areas: update }),
          selectedAreas: areas.selectedAreas.filter((id: number) => {
            const area = update.find((ar) => ar.id === id);
            return filterStaleAreas(area);
          })
        };

        dispatch(updateSet.success(payload));
        dispatch(showAreaAction(data));
      })
      .catch((e) => dispatch(updateSet.error(e)));
  };
}

/**/
export function fetchZonesAction() {
  return (dispatch, getState) => {
    const { areas } = getState() as TState;
    if (areas.zones) return;

    const url = `${ENDPOINTS.ZONES}?countryId=NO`;
    dispatch(fetchZonesSet.request());

    http
      .send(url)
      .then(({ data }: THttpResponse<TZone[]>) =>
        dispatch(fetchZonesSet.success({ zones: data }))
      )
      .catch((e) => dispatch(fetchZonesSet.error(e)));
  };
}

/**/
export function resetSelectedAction() {
  return (dispatch) => {
    SettingsService.writeSettings({ [SettingsService.SELECTED_AREAS]: [] });

    dispatch({
      type: EAreasActions.RESET_SELECTED,
      payload: { selectedAreas: [] }
    });
  };
}
