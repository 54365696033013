import { basicInitialState } from '../_utils/basicInitialState';
import { TDictionariesState } from 'store';

export const defaultFilterRanges = {
  lengthRange: { min: 0, max: 220 },
  powerRange: { min: 150, max: 16000 },
  tonnageRange: { min: 0, max: 16000 },
  yearRange: { min: 1950, max: new Date().getFullYear() }
};

export const dictionariesInitialState: TDictionariesState = {
  ...basicInitialState,
  companyStatuses: [],
  countries: [],
  countriesISO: [],
  crmVesselTypes: [],
  engines: [],
  filterRanges: defaultFilterRanges,
  fishFarmStatuses: [],
  fishFarmTypes: [],
  languages: [],
  manufacturers: [],
  powerBiReports: {
    activityProfile: {},
    biolytics: {},
    harvestingActivities: {}
  } as any,
  promotionCountries: [],
  proposalRejectReasons: [],
  species: [],
  vesselStatuses: [],
  vesselTypes: []
};
