import React from 'react';
import Checkbox from 'antd/lib/checkbox';
import classnames from 'classnames';
import dayjs from 'dayjs';
import Tag from 'antd/lib/tag';
import { useHistory } from 'react-router-dom';

import './AreaItem.scss';
import { EditDeleteGroup } from 'components';
import { ROUTES } from 'other';
import { TArea } from 'types';

/**/
type Props = {
  area: TArea;
  isChecked: boolean;
  onRemove: () => void;
  onSelect: () => void;
};

/**
 *
 */
function AreaItem(props: Props) {
  const { area, isChecked, onRemove, onSelect } = props;
  const history = useHistory();
  const handleEdit = () => history.push(`${ROUTES.AREA_EDITOR}/${area.id}`);

  const isFresh =
    dayjs(area.end).isValid() &&
    dayjs(area.end || null).unix() * 1000 > Date.now();

  const cls = classnames('AreaItem__name ellipsis', {
    'AreaItem__name--disabled': !isFresh
  });

  return (
    <li className="AreaItem">
      <div className="AreaItem__info">
        <Checkbox checked={isChecked} disabled={!isFresh} onClick={onSelect}>
          <div className={cls}>{area.name}</div>
          {isFresh || (
            <Tag className="AreaItem__tag" color="orange">
              Expired
            </Tag>
          )}
        </Checkbox>
      </div>

      <EditDeleteGroup
        className="AreaItem__controls"
        onDelete={onRemove}
        onEdit={handleEdit}
      />
    </li>
  );
}

/**/
export default AreaItem;
