import { push } from 'connected-react-router';

import { ENDPOINTS } from 'other';
import { fetchSet, markAsReadSet } from './notificationConstants';

import { http, THttpResponse } from 'services';
import { TNotification } from 'types';
import { TPagination } from '../news/news/newsModel';
import { TState } from '../appStateModel';

/**/
const NOTIFICATION_REFRESH_INTERVAL = 120 * 1000 * 60;

/**
 *
 */
export function fetchNotificationsAction(fetchMore?: boolean) {
  return (dispatch, getState) => {
    const {
      notifications: { isPending, notifications, pagination }
    }: TState = getState();

    if ((notifications?.length > 0 && !fetchMore) || isPending) return;

    const requestedPage: number = fetchMore ? pagination.page + 1 : 0;
    dispatch(fetchNotifications(requestedPage, fetchMore));
  };
}

/**
 *
 */
function fetchNotifications(requestedPage: number, fetchMore?: boolean) {
  return (dispatch, getState) => {
    const {
      notifications: { notifications, pagination }
    }: TState = getState();

    const url = `${ENDPOINTS.NOTIFICATIONS}?page=${requestedPage}&size=${pagination.size}`;
    fetchMore || dispatch(scheduleRefreshAction());
    dispatch(fetchSet.request());

    http
      .send(url)
      // @ts-ignore
      .then(({ data, total }: THttpResponse<TNotification[]>) => {
        const list: TNotification[] = fetchMore
          ? [...(notifications || []), ...data]
          : data;

        const _pagination: TPagination = {
          ...pagination,
          page: requestedPage,
          total: total
        };

        dispatch(
          fetchSet.success({
            notifications: list,
            pagination: _pagination
          })
        );
      })
      .catch((e) => dispatch(fetchSet.error(e)));
  };
}

/**
 *
 */
function scheduleRefreshAction() {
  return (dispatch) =>
    setTimeout(
      () => dispatch(fetchNotifications(0, false)),
      NOTIFICATION_REFRESH_INTERVAL
    );
}

/**
 *
 */
export function markAsReadAction(items: number[]) {
  return (dispatch, getState) => {
    dispatch(markAsReadSet.request());

    const body = items.map((id: number) => ({
      id: id,
      isRead: true
    }));

    http
      .send({
        body: body,
        method: 'POST',
        url: ENDPOINTS.NOTIFICATIONS_READ
      })
      .then(() => {
        const { notifications }: TState = getState();

        const update = notifications.notifications.map((n: TNotification) => {
          if (items.length === 0) return { ...n, read: true };
          if (items[0] === n.id) return { ...n, read: true };
          return n;
        });

        dispatch(markAsReadSet.success({ notifications: update }));
      })
      .catch((e) => dispatch(markAsReadSet.error(e)));
  };
}
