import React from 'react';
import Modal from 'antd/lib/modal';

import './NotificationMenu.scss';
import { IconCustom } from '../../common/IconCustom/IconCustom';
import { LinkButton } from 'components';
import NotificationList from './NotificationList';

import { TInjectedModalStateProps, withModalState } from '../../withModalState';
import { TNotification } from 'types';

/**/
type Props = TInjectedModalStateProps & {
  hasMore: boolean;
  isLoading: boolean;
  notifications: TNotification[];
  onClear: () => void;
  onFetch: (fetchMore?: boolean) => void;
  onItemClick: (id: number) => void;
};

/**/
const defaultModalProps = {
  bodyClassName: 'NotificationMenu__body',
  className: 'NotificationMenu__modal',
  closable: false,
  destroyOnClose: true,
  footer: null,
  icon: null,
  rootClassName: 'NotificationMenu__root',
  title: null
};

/**
 *
 */
class NotificationMenu extends React.PureComponent<Props> {
  /**/
  componentDidMount() {
    this.props.onFetch();
  }

  /**/
  handleClick = () => {
    const { isModalVisible, onModalHide, onModalShow } = this.props;
    isModalVisible ? onModalHide() : onModalShow();
  };

  /**/
  handleItemClick = (id: number) => {
    this.props.onModalHide();
    this.props.onItemClick(id);
  };

  /**/
  handleMore = () => this.props.onFetch(true);

  /**/
  render() {
    const { hasMore, isModalVisible, notifications, onClear, onModalHide } =
      this.props;
    if (!notifications) return null;

    const unreadQty = notifications.filter(
      (n: TNotification) => !n.read
    ).length;

    const modalProps = {
      ...defaultModalProps,
      onCancel: onModalHide,
      open: isModalVisible
    };

    return (
      <section className="NotificationMenu">
        <LinkButton
          className="NotificationMenu__button"
          onClick={this.handleClick}
        >
          <IconCustom title="Show notifications" type="bell" />
          {unreadQty > 0 && (
            <div className="NotificationMenu__counter">{unreadQty}</div>
          )}
        </LinkButton>

        <Modal {...modalProps}>
          <NotificationList
            onClear={onClear}
            onClose={onModalHide}
            onItemClick={this.handleItemClick}
            onMore={this.handleMore}
            hasMore={hasMore}
            notifications={notifications}
          />
        </Modal>
      </section>
    );
  }
}

/**/
export default withModalState(NotificationMenu);
