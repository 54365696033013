import PropTypes from 'prop-types';

import {
  PTCountryISO,
  PTEngine,
  PTVesselType,
  TCountryISO,
  TEngine,
  TVesselType
} from './dictionaries';
import {
  PTCreatedBy,
  PTLocaleSet,
  TCreatedBy,
  TIdName,
  TLocaleSet
} from './general';
import { PTMedia, TMedia, TPhotoSet } from './media';
import { PTOwner, TOwner } from './owner';
import { TMapEntityState } from './map';
import { TVesselExtras } from './extendedFields/common';

export type TVesselTrack = TVesselTrackData & {
  vesselId: number;
};

export type TVesselTrackExt = TVesselTrack & {
  color: string;
  flag: string;
  name: string;
  showTooltip: boolean;
  vesselType: number;
};

export type TVesselTrackPoint = {
  heading: number;
  id: number;
  lastUpdate: string;
  latitude: number;
  longitude: number;
  speed: number;
  status: string;
};

export type TVesselTrackVector = {
  start: {
    latitude: number;
    longitude: number;
  };
  end: {
    latitude: number;
    longitude: number;
  };
  color: string;
  heading: number;
  id: string; // do we need it?
  lastUpdate: string;
  speed: number;
  vesselId: number;
};

export type TVesselOfCompany = {
  flag?: TCountryISO;
  mediaPath?: string;
  vesselId: number;
  vesselName: string;
};

export const PTVesselOfCompany = {
  flag: PropTypes.exact(PTCountryISO),
  mediaPath: PropTypes.string,
  vesselId: PropTypes.number.isRequired,
  vesselName: PropTypes.string.isRequired
};

export type TVessel = {
  actionHistory: TActionHistory[];
  id: number;
  flag: string;
  name: string;
  createdBy: TCreatedBy;
  fisheriesType: {
    id: number;
    value: TLocaleSet;
  };
  registrationNumber: string;
  registrationDate: string;
  harbourNumber: string;
  mmsi: string;
  imo: string;
  callSign: string;
  port: string;
  type?: TVesselType;
  buildYear: number;
  yard: string;
  built: TBuilt;
  vesselClass: TVesselClass;
  vesselSizes: TDimensions;
  vesselType: TVesselType;
  engine: TEngine;
  owner: TOwner;
  managerId: number;
  photo: TPhotoSet;
  extendedFields: TVesselExtras;
  noSignalDays?: number;
  status?: { id: number };
  statusId?: number;
};

export type TVesselExName = {
  flag: string;
  name: string;
  owner: TIdName;
};

export const PTVesselExName = {
  flag: PropTypes.string,
  name: PropTypes.string,
  owner: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })
};

export type TActionHistory = {
  after: TVesselExName;
  before: TVesselExName;
  date: string; // YYYY-MM-DD
  id: number;
};

export const PTActionHistory = {
  after: PropTypes.exact(PTVesselExName),
  before: PropTypes.exact(PTVesselExName),
  date: PropTypes.string,
  id: PropTypes.number
};

export type TBuilt = {
  buildYear: number;
  design: string;
  designer: { id: number; name: string; serviceProviderId: number };
  vesselYard: {
    country: TCountryISO;
    id: number;
    name: string;
    serviceProviderId: number;
  };
  yard: string; // todo remove
  notableMentions: string;
};

export const PTBuilt = {
  buildYear: PropTypes.number,
  design: PropTypes.string,
  designer: PropTypes.exact({
    country: PropTypes.exact(PTCountryISO),
    id: PropTypes.number,
    name: PropTypes.string,
    serviceProviderId: PropTypes.number
  }),
  notableMentions: PropTypes.string,
  vesselYard: PropTypes.exact({
    country: PropTypes.exact(PTCountryISO),
    id: PropTypes.number,
    name: PropTypes.string,
    serviceProviderId: PropTypes.number
  }),
  yard: PropTypes.string // todo remove
};

export type TVesselClass = {
  classNotation: string;
  notableMentions: string;
  classification: {
    id: number;
    name: string;
  };
};

export const PTVesselClass = {
  classNotation: PropTypes.string,
  classification: PropTypes.exact({
    id: PropTypes.number,
    name: PropTypes.string
  }),
  notableMentions: PropTypes.string
};

export type TDimensions = {
  bruttotons: number;
  depth: number;
  length: number;
  lengthOverall: number;
  nettotons: number;
  width: number;
  draft: number;
  deadWeight: number;
  displacement: number;
};

export const PTDimensions = {
  bruttotons: PropTypes.number,
  deadWeight: PropTypes.number,
  depth: PropTypes.number,
  displacement: PropTypes.number,
  draft: PropTypes.number,
  length: PropTypes.number,
  lengthOverall: PropTypes.number,
  nettotons: PropTypes.number,
  width: PropTypes.number
};

export type TVesselLocation = {
  flag: string;
  heading: number;
  id: number;
  isHidden?: boolean; // Front-end only field.
  lastUpdate: string;
  latitude: number;
  longitude: number;
  name: string;
  noSignalDays?: number;
  photo: TMedia;
  speed: number;
  status: string;
  time: string;
  type: number; // vesselType
};
export type TVesselLocationExt = TVesselLocation & {
  uiState?: TMapEntityState;
};

export const PTVesselPhotoSet = {
  big: PropTypes.exact(PTMedia),
  id: PropTypes.number.isRequired,
  original: PropTypes.exact(PTMedia),
  position: PropTypes.number,
  thumbnail: PropTypes.exact(PTMedia)
};

export const PTPhotoItem = {
  description: PropTypes.string.isRequired,
  fullscreen: PropTypes.string.isRequired,
  original: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired
};

export type TVesselShort = {
  id: number;
  flag: TCountryISO;
  name: string;
};

export const PTVesselShort = {
  flag: PropTypes.exact(PTCountryISO),
  id: PropTypes.number,
  name: PropTypes.string
};

export const PTVessel = {
  actionHistory: PropTypes.arrayOf(PropTypes.exact(PTActionHistory)),
  buildYear: PropTypes.number,
  built: PropTypes.exact(PTBuilt),
  callSign: PropTypes.string,
  createdBy: PropTypes.exact(PTCreatedBy),
  engine: PropTypes.exact(PTEngine),
  extendedFields: PropTypes.object,
  fisheriesType: PropTypes.exact({
    id: PropTypes.number,
    value: PropTypes.exact(PTLocaleSet)
  }),
  flag: PropTypes.string.isRequired,
  harbourNumber: PropTypes.string,
  id: PropTypes.number.isRequired,
  imo: PropTypes.string,
  managerId: PropTypes.number,
  mmsi: PropTypes.string,
  name: PropTypes.string.isRequired,
  owner: PropTypes.exact(PTOwner),
  phone: PropTypes.string,
  photo: PropTypes.exact(PTVesselPhotoSet),
  port: PropTypes.string,
  registrationDate: PropTypes.string,
  registrationNumber: PropTypes.string,
  statusId: PropTypes.number,
  type: PropTypes.exact(PTVesselType),
  vesselClass: PropTypes.exact(PTVesselClass),
  // vesselId: PropTypes.number,
  vesselSizes: PropTypes.exact(PTDimensions),
  vesselType: PropTypes.exact(PTVesselType),
  yard: PropTypes.string
};

/**
 *
 */
export type TCatch = {
  specie: string;
  weightKg: number;
};

export type TCatchList = {
  date: string;
  totalWeightKg: number;
  catches: TCatch[];
};

export type TFinStatement = {
  turnOver: number;
  profit: number;
};

export type TVesselActivity = {
  start: string; // "2024-04-11T14:53:22.969Z"
  end: string;
  type: string;
};

export type TVesselTrackData = {
  activities: TVesselActivity[];
  catches: TCatchList[];
  financialStatementHighlights: TFinStatement;
  locations: TVesselTrackPoint[];
};
