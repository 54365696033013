import { EMapLayer } from '../../types';

/**/
const DEPTH_VALUES = [
  0.5, 1.5, 2.6, 3.8, 5.1, 6.4, 7.9, 9.6, 11.4, 13.5, 15.8, 18.5, 21, 25, 29,
  34, 40, 47, 55, 65, 77, 92, 110, 130, 155, 186, 222, 266, 318, 380, 454, 541,
  644, 763, 902, 1062, 1245, 1452, 1684, 1941, 2225, 2533, 2865, 3220, 3597,
  3992, 4405, 4833, 5274 /*, 5727*/
];

/**/
const ZOOPLANKTON_DEPTH_VALUES = [
  0.5, 2, 3, 4, 5, 6, 8, 10, 11, 13, 16, 18, 22, 25, 29, 34, 40, 47, 56, 66, 78,
  92, 110, 131, 156, 186, 222, 266, 318, 380, 454, 541, 644, 763, 902, 1062,
  2000, 3000, 3500 /*, 4000*/
];

/**/
const depthDefaults = {
  depth: 0.5,
  depthRange: { min: 0.5, max: 5274 /*5727*/ }
};

/**/
export const DEFAULT_LAYER_VALUES = {
  [EMapLayer.PHYTOPLANKTON]: {
    ...depthDefaults,
    range: [0, 70]
  },
  [EMapLayer.SALINITY]: {
    ...depthDefaults,
    range: [0, 50]
  },
  [EMapLayer.SEATEMP]: {
    ...depthDefaults,
    range: [-10, 40]
  },
  [EMapLayer.SURFACETEMP]: {
    ...depthDefaults,
    range: [-10, 40]
  },
  [EMapLayer.ZOOPLANKTON]: {
    depth: 0.5,
    depthRange: { min: 0.5, max: 3500 /*4000*/ },
    range: [0, 150]
  }
};

/**/
export const DEPTH_AVAILABILITY_LAYERS = [
  EMapLayer.PHYTOPLANKTON,
  EMapLayer.SALINITY,
  EMapLayer.SEATEMP,
  EMapLayer.ZOOPLANKTON
];

/**/
export const LAYER_DEPTHS_MAP = {
  [EMapLayer.PHYTOPLANKTON]: DEPTH_VALUES,
  [EMapLayer.SALINITY]: DEPTH_VALUES,
  [EMapLayer.SEATEMP]: DEPTH_VALUES,
  [EMapLayer.SURFACETEMP]: DEPTH_VALUES,
  [EMapLayer.ZOOPLANKTON]: ZOOPLANKTON_DEPTH_VALUES
};
