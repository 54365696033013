import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';

import { getDisplayName } from 'components';
import { EUserAuthority } from 'types';
import { TState } from 'store';

export function withPlaceholderPage<T>(
  WrappedComponent: ComponentType<T>,
  permissions: EUserAuthority[],
  PlaceholderComponent: ComponentType
): ComponentType<T> {
  function HOC(props) {
    const userPermissions = useSelector(
      ({ session }: TState) => session.user?.userInfo?.authorities || []
    );
    const isAuthored = permissions.every((permission) =>
      userPermissions.includes(permission)
    );

    return isAuthored ? (
      <WrappedComponent {...props} />
    ) : (
      <PlaceholderComponent />
    );
  }

  HOC.displayName = `withPlaceholderPage(${getDisplayName(WrappedComponent)})`;

  return HOC;
}
